.image-magnify-img {
    animation: imageScale 0.4s;
    @apply w-full h-[500px] object-cover p-6;
  }
  
.breadcrumb-box span {
    @apply text-[#7B7B7B] font-sans text-[14px];
}

.feature-product-container .product-item > div:first-child {
    @apply bg-white;
}

.compact-product-card-wrapper {
    @apply pt-2 flex flex-col;
}
.compact-product-card-wrapper .content-wrapper {
    @apply flex flex-col justify-between h-full;
}
.compact-product-card-wrapper img {
    @apply w-72 h-72 min-h-[288px] object-contain m-auto px-0;
}
  
@keyframes imageScale {
    from {
        scale: 1.5;
        opacity: 0;
    }
    to {
        scale: 1;
        opacity: 1;
    }
}