@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: "SegoeUI";
  src: url("../src/assets/fonts/SEGOEUI.TTF");
}

@font-face {
  font-family: "Metropolis";
  src: url("../src/assets/fonts/metropolis.regular.otf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import '~react-loading-skeleton/dist/skeleton.css';
/* purgecss end ignore */

.product-category-wrapper {
  scrollbar-width: none;
  scrollbar-color: #DE4F2D;
}

.text-wrap {
  text-wrap: wrap;
}

.pre-wrap-custom {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Roboto;
}

@media screen and (max-width: 480px) {
  .custom-scrollbar::-webkit-scrollbar {
    @apply h-1;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-[#66DB9F] rounded-md h-1 z-10;
  }
}
