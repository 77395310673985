.info-tab .active{
    animation: pulse 0.4s cubic-bezier(0.4, 0, 0.6, 1);
    @apply transition-all duration-500 md:text-black md:bg-aquamarine;
}

.faq-tab .question-box .answer-box{
    @apply max-h-0 transition-all duration-300 overflow-hidden opacity-0;
}

.faq-tab .question-box.active .answer-box{
    @apply opacity-100 max-h-[500px] pt-8;
}

#_no-clickjacking-0.close-box, .close-box {

    @apply h-0 transition-all duration-200 !overflow-hidden !opacity-0;
}

.open-box {
    @apply transition-all duration-300 opacity-100 w-full block h-full md:pt-8;
}

.render-tab .active {
    animation: blurOnSwitchTab 0.6s;
}

@keyframes blurOnSwitchTab {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes onPulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
