@media screen and (min-width: 1024px) {

    .feature-product-0 {
        @apply xl:min-w-[650px];
    }

    .feature-product-0 p {
        @apply lg:w-4/5 mx-auto;
    }

    .feature-product-0 .compact-product-card-wrapper img{
        width: 520px;
        height: 410px;
        margin: auto;
        object-fit: contain;
    }
    
    .feature-product-1 img{
        width: 260px;
        height: 200px;
        margin: auto;
        object-fit: contain;
    }
    
    .feature-product-2 img{
        width: 260px;
        height: 200px;
        margin: auto;
        object-fit: contain;
    }
    
    .feature-product-3 img {
        width: 250px;
        height: 190px;
        margin: auto;
        object-fit: contain;
    }

    .feature-product-3 > div {
        @apply p-0
    }
    
    .feature-product-3 .compact-product-card-wrapper {
        display: flex;
        flex-direction: row-reverse;
    }

    .feature-product-3 .compact-product-card-wrapper .content-wrapper {
        @apply justify-center;
    }

    .feature-product-0 .compact-product-card-wrapper .content-wrapper {
        @apply justify-center;
    }
    
}

.feature-product-0 img{
    width: 300px;
    height: 230px;
    margin: auto;
    object-fit: contain;
}

.feature-product-1 img{
    width: 174px;
    height: 174px;
    margin: auto;
    object-fit: contain;
}

.feature-product-2 img{
    width: 174px;
    height: 174px;
    margin: auto;
    object-fit: contain;
}

.feature-product-3 img {
    width: 180px;
    height: 140px;
    margin: auto;
    object-fit: contain;
}
